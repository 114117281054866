<template>
    <about-module-component/>
</template>

<script>
    import AboutModuleComponent from "@/components/admin/AboutAsModuleComponent";

    export default {
        name: "AboutModule",
        title: "Marco Social | Private",
        components: {AboutModuleComponent}
    }
</script>

<style scoped>

</style>